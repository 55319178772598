











































































  import { Component, Vue, Prop, Watch } from "vue-property-decorator";
  import moment from "moment";
  import Pagination from "@/components/pagination/Pagination.vue"; 
  import { IPanelData } from "@/data/commonModel";
  import {
    IMediaStoreModel,
    IMediaStoreQuery, 
  } from "../data/mediaStoreModel"; 
  import { 
    updateVideo,
    deleteVideo, 
    getVideos,
  } from "@/remote/video";
 import { MediaStoreModule } from "@/store/modules/video";
  @Component({
    components: {
      Pagination,
    },
  })
  export default class extends Vue {
    @Prop({
      required: true,
    })
    panelObj!: IPanelData;  

    listLoading: boolean = false;
    list: IMediaStoreModel[] = []; 
    allList: IMediaStoreModel[] = [];
    listQuery: IMediaStoreQuery = {
      type: "all",
      searchText:''
    }; 

    @Watch("panelObj", {
      deep: true,
      immediate: true,
    })
    _onPanelObjChange(value: IPanelData) {
  this.listQuery = value;
      this.refreshTable();
    
    console.log("_onPanelObjChange");
    console.log(value);
    
    }
    filterResult(){
if ( this.listQuery.type == "all") {
      this.list = this.allList;
    } else {
      let tempList: IMediaStoreModel[] = [];
      this.allList.forEach((element: IMediaStoreModel) => {
        if (
          (element.isPublished &&  this.listQuery.type === "published") ||
          (!element.isPublished &&  this.listQuery.type === "unpublished")
        ) {
          tempList.push(element);
        }
      });

      this.list = tempList;
    }
    }
    mounted() { 
      this.refreshTable();
    }

    public refreshTable() {
      this._fetchRecords({
        ...this.listQuery,
      });
    }
 

    async _fetchRecords(params: IMediaStoreQuery) {
      this.listLoading = true;
      try {
        const res = await getVideos(params);
        if (res && res.data && res.data.data) {
          const { data } = res.data;
           let publishCount = 0;
          data.items.forEach((element: IMediaStoreModel) => {
            element.createdAtText = moment(element.createdAt).format(
              "YYYY-MM-DD"
            );
             if (element.isPublished) {
            publishCount++;
          }
          });
          this.allList =  data.items;
            MediaStoreModule.SET_STORE_COUNET_RES({
          allCount: this.list.length,
          publishedCount: publishCount,
          unpublishedCount: this.allList.length - publishCount,
        });
         this.filterResult();
        } else {
          this.list = [];
        }
      } catch (error) {
        // console.log(error);
        // console.log("getPackageRecords 出错了");
      }
      setTimeout(() => {
        this.listLoading = false;
      }, 0.5 * 1000);
    }
 

    async _onClickEdit(id: number) {
      this.$emit("onEditVideo",  this.list[id]);
    }

    _onChangeSwitch(value: boolean, id: string, index: number) {
      
      this.$confirm("", value ? "确认发布这个视频吗？" : "确认取消发布吗？", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        center: true,
      })
        .then(() => {
          this._onUpdatePublishStatus(  id,  this.list[index]);
        })
        .catch(() => {
          this.list[index].isPublished = !value;
        });
    }

    async _onUpdatePublishStatus( id: string, media: IMediaStoreModel) {
      try {
        this.listLoading = true;
        const res = await updateVideo(id,media);
        this.listLoading = false;
        if (res.data.resultCode === 0) {
          this.refreshTable();
          this.$message.success("操作成功"); 
        }
      } catch {
        this.listLoading = false;
      }
    }

    // 删除
    _onClickDelete(id: string) {
      this.$confirm("", "确认删除这个视频吗？", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        center: true,
      })
        .then(() => {
          this._deleteVideo(id);
          this._fetchRecords(this.listQuery);
        })
        .catch(() => {});
    }

    async _deleteVideo(id: string) {
      try {
        this.listLoading = true;
        const res = await deleteVideo(id);
        this.listLoading = false;
        if (res && res.status === 200) {
          this._fetchRecords(this.listQuery);
          this.$message.success("操作成功"); 
        }
      } catch {
        this.listLoading = false;
      }
    }
      onCopy(id: string) {
    const save = function (e: any) {
      e.clipboardData.setData("text/plain", id);
      e.preventDefault(); // 阻止默认行为
    };
    document.addEventListener("copy", save); // 添加一个copy事件
    document.execCommand("copy"); // 执行copy方法
    this.$message({ message: "复制成功", type: "success" });
  }
  }
