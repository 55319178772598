




























import { Component, Vue } from "vue-property-decorator";
import PanelGroup from "@/components/PanelGroup.vue";
import MediaStoreTable from "./components/MediaStoreTable.vue";
import MediaStoreEdit from "./components/MediaStoreEdit.vue";
import { ITabModel, IPanelData } from "@/data/commonModel";
import { IMediaStoreModel } from "./data/mediaStoreModel";
import { MediaStoreModule } from "@/store/modules/video";
@Component({
  components: {
    PanelGroup,
    MediaStoreEdit,
    MediaStoreTable,
  },
})
export default class extends Vue {
  editDialogVisible: boolean = false; 
  editType: string = "add";
  panelObj: IPanelData = { type: "all", searchText: "" ,show:true};
  dataModel: IMediaStoreModel | null = null;

  get tabs(): ITabModel[] {
    const arr = [
      {
        title: `全部(${MediaStoreModule.storeCountRes.allCount})`,
        key: "all",
      },
      // {
      //   title: `已发布(${MediaStoreModule.storeCountRes.publishedCount})`,
      //   key: "published",
      // },
      // {
      //   title: `未发布(${MediaStoreModule.storeCountRes.unpublishedCount})`,
      //   key: "unpublished",
      // },
    ];
    return arr;
  }
 

  mounted() { 
  }

  _handleSwitchTab(props: IPanelData) {
    this.panelObj = props; 
  }

  _onClickAdd() {
    this.editType = "add";
    this.dataModel = null;
    this.editDialogVisible = true;
  } 

  _handleEditVideoSuccess(type: string) { 
    console.log('_handleEditVideoSuccess')
    const ref: MediaStoreTable = this.$refs["table"] as MediaStoreTable;
    ref.refreshTable();
  }

  _handleEditVideo(value: IMediaStoreModel) {
    this.editType = "edit";
    this.editDialogVisible = true;
    this.dataModel = JSON.parse(JSON.stringify(value));
  }
}
