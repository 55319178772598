import {
  VuexModule,
  Module,
  Mutation,
  getModule,
  Action
} from 'vuex-module-decorators'
import store from '@/store'
import { IBannerCountRes } from '@/pages/banner/data/bannerModel' 

export interface IMediaStoreState {
  storeCountRes: IBannerCountRes 
}

@Module({ dynamic: true, store, name: 'store' })
class Store extends VuexModule implements IMediaStoreState {
  public storeCountRes = {
    allCount: 0,
    publishedCount: 0,
    unpublishedCount: 0
  }
   


  @Mutation
  public SET_STORE_COUNET_RES(res: IBannerCountRes) {
    // console.log("SET_STORE_COUNET_RES")
    // console.log(res)
    this.storeCountRes = res
  }
}

export const MediaStoreModule = getModule(Store)
