


























































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import ImgUpload from "@components/ImgUpload.vue";
import { Form as ElForm } from "element-ui";
import { IMediaModel } from "@/data/commonModel";
import { addVideo, updateVideo, updateCoverImage } from "@/remote/video";
import { IMediaStoreReqModel, IMediaStoreModel } from "../data/mediaStoreModel";
import { UserModule } from "@/store/modules/user";
import PopupPreviewVideo from "@components/PopupPreviewVideo.vue";
// 装饰器模式
@Component({
  components: {
    ImgUpload,
    ElForm,
    PopupPreviewVideo,
  },
})
export default class extends Vue {
  // 显示或隐藏
  @Prop({
    default: false,
  })
  dialogFormVisible!: boolean;
  // 编辑类型
  @Prop({
    default: "add",
  })
  editType!: string;
  // editType = 'edit'时所需要的数据
  @Prop() dataModel!: IMediaStoreModel;
  previewDialogVisible: boolean = false;
  // Data
  formLabelWidth: string = "140px";
  formValueWidth: number = 450;
  formParams: IMediaStoreModel = {
    id: "",
    title: "",
    description: "",
    createdAtText: "",
    createdAt: "",
    isPublished: false,

    url: null,
    coverURL: null,
  };
  videoFile: any;
  coverImageFile: any;
  videoId: string = "";
  saveBtnLoading: boolean = false;
  validatVideo(rule: any, value: any, callback: Function) {
    //  console.log('validatVideo')
    //  console.log(this.videoFile)
    if (this.editType != "add") {
      callback();
    }
    if (
      this.videoFile === undefined ||
      this.videoFile === "" ||
      this.videoFile === null
    ) {
      callback(new Error("请选择视频"));
    } else {
      callback();
    }
  }
  // Rules
  rules = {
    title: {
      type: "string",
      required: true,
      trigger: "focus",
      message: "请输入视频标题",
    },
    url: {
      required: true,
      message: "请选择视频",
      trigger: "blur",
      validator: this.validatVideo,
    },

    // coverImages: {
    //   type: "array",
    //   required: true,
    //   trigger: "focus",
    //   message: "请设置视频封面图片",
    // },
  };

  // Computed
  get uploadImageItemWidth() {
    return `${(this.formValueWidth - 20) / 5}px`;
  }

  // Watch
  @Watch("dataModel", {
    immediate: true,
    deep: true,
  })
  oModelChange(value: IMediaStoreModel) {
    if (!value) return;
    // console.log("oModelChange", value);
    const model = JSON.parse(JSON.stringify(value));
    this.videoId = model.id;
    this.formParams = value;
  }

  @Watch("formParams", {
    immediate: true,
    deep: true,
  })
  onFormParamsChange(value: IMediaStoreModel) {
    const { url, coverURL, title } = value;
    const form = this.$refs["packageForm"] as ElForm;
    if (form && url && url.length > 0) {
      form.validateField("url");
    }
    if (form && coverURL && coverURL.length > 0) {
      form.validateField("coverURL");
    }
    if (form && title && title.length > 0) {
      form.validateField("title");
    }
  }

  // Methods
  _beforeModalClose() {
    this.$emit("update:dialogFormVisible", false); // 直接修改父组件的属性
  }

  _hide() {
    this.$emit("update:dialogFormVisible", false);
  }

  _onClickConfirm() {
    const form = this.$refs["packageForm"] as ElForm;
    form.validate((valid: Boolean) => {
      if (valid) {
        this._showConfirmToast();
      } else {
        return false;
      }
    });
  }

  _showConfirmToast() {
    this.$confirm(
      this.editType === "edit"
        ? "确认更新该视频吗？"
        : "确认保存为新的视频吗？",
      "提示",
      {
        confirmButtonText: this.editType === "edit" ? "更新" : "保存",
        cancelButtonText: "取消",
        center: true,
      }
    )
      .then(() => {
        this._handleFormData();
      })
      .catch(() => {});
  }

  _onClickCancel() {
    this.$confirm("", "确认放弃编辑吗？", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      center: true,
    })
      .then(() => {
        this._hide();
      })
      .catch(() => {});
  }

  // 处理表单数据
  _handleFormData() {
    this.saveBtnLoading = true;
    if (this.editType === "edit") {
      this._updateMedia(this.formParams);
    } else {
      this._create(this.formParams);
    }
  }

  _selectImage(e: any) {
    console.log(e);
    const file = e.target.files[0]; // 获取选中的文件
    this.coverImageFile = file;
  }
  _selectvideo(e: any) {
    console.log(e);
    const file = e.target.files[0]; // 获取选中的文件
    this.videoFile = file;
    if (
      [
        "video/mp4",
        "video/ogg",
        "video/flv",
        "video/avi",
        "video/wmv",
        "video/rmvb",
        "video/mov",
      ].indexOf(file.type) === -1
    ) {
      this.$message.error("请上传正确的视频格式");
      return false;
    }
    console.log(file.size / 1024 / 1024);
    if (file.size / 1024 / 1024 > 500) {
      this.$message.error("视频大小不能超过500MB");
      return false;
    }
  }
  // 新增
  async _create(reqModel: IMediaStoreModel) {
    try {
      const formData = new FormData(); // 创建form对象
      formData.append("video", this.videoFile);
      formData.append("coverImage", this.coverImageFile);
      formData.append("title", this.formParams.title);
      formData.append("wineryId", UserModule.wineryId);
      formData.append("description", this.formParams.description);
      console.log(this.videoFile);
      console.log(formData);
      console.log(UserModule.wineryId);
      // formData.append('file', file)
      const res = await addVideo(formData);
      if (res.status === 200) {
        this.$message.success("保存成功");
        console.log("emit create");
        this.$emit("create", this.editType);
        this._hide();
      }
      this.saveBtnLoading = false;
    } catch {
      this.saveBtnLoading = false;
      // console.log("_createPackage出错了");
    }
  }

  // 编辑
  async _updateMedia(reqModel: IMediaStoreModel) {
    try {
      if (this.changeCover && this.coverImageFile) {
        const formData = new FormData(); // 创建form对象
        formData.append("file", this.coverImageFile);
        const res = await updateCoverImage(this.videoId, formData);
      }
      const res = await updateVideo(this.videoId, reqModel);
      if (res.status === 200) {
        this.$message.success("保存成功");
        console.log("emit update");
        this.$emit("update", this.editType);
        this._hide();
      }
      this.saveBtnLoading = false;
    } catch {
      this.saveBtnLoading = false;
      // console.log("_updatePackage 出错了");
    }
  }

  _onInput(event: any) {
    var value = event.target.value.trimStart();
    this.formParams.description = value;
  }
  changeCover: boolean = false;
  _onClickChangeCover() {
    this.changeCover = true;
  }
  _onCancelChangeCover() {
    this.changeCover = false;
  }
}
